import axios from "axios";
import { thirdPartServerUrl } from "../config/keys";
import socketManager from "../socketManager/SocketManager";

// const configuration = {
//   iceServers: [
//     {
//       urls: 'stun:stun.relay.metered.ca:80',
//     },
//     {
//       urls: 'turn:in.relay.metered.ca:80',
//       username: 'e84ad577c9836ae72e23dc2b',
//       credential: 'B1K3fxw/DsSUgLj+',
//     },
//     {
//       urls: 'turn:in.relay.metered.ca:80?transport=tcp',
//       username: 'e84ad577c9836ae72e23dc2b',
//       credential: 'B1K3fxw/DsSUgLj+',
//     },
//     {
//       urls: 'turn:in.relay.metered.ca:443',
//       username: 'e84ad577c9836ae72e23dc2b',
//       credential: 'B1K3fxw/DsSUgLj+',
//     },
//     {
//       urls: 'turns:in.relay.metered.ca:443?transport=tcp',
//       username: 'e84ad577c9836ae72e23dc2b',
//       credential: 'B1K3fxw/DsSUgLj+',
//     },
//   ]
// };

let iceConfiguration;

const fetchIceServers = async () => {
  try {
    const response = await axios.post(
      `${thirdPartServerUrl}/v1/content/createStunServer`
    );
    if (response && response.data) {
      iceConfiguration = response.data.data.iceServers;
      console.log("Fetched ICE servers:", iceConfiguration);
    } else {
      throw new Error("No data received from server");
    }
  } catch (error) {
    console.error("Error fetching ICE servers:", error);
  }
};

await fetchIceServers();

const rtcConfig = {
  iceServers: iceConfiguration,
};

const MAX_RETRIES = 3;

const useWebRTC = () => {
  const createPeerConnectionCreator = async (
    stream,
    userId,
    mainRoom,
    peerConnections,
    retryCount
  ) => {
    if (retryCount === MAX_RETRIES) {
      // TODO msg and loader false
      const retryData = {
        msg: "Failed to connect to the live stream. Please change your network connection!",
        isLoader: false,
        socketId: userId,
        maxLimit: true,
      };
      socketManager.retryLoader(retryData);
    }

    const pc = new RTCPeerConnection(rtcConfig);
    pc.userId = userId;
    pc.mainRoom = mainRoom;

    if (stream && stream.getTracks().length > 0) {
      stream.getTracks().forEach((track) => {
        pc.addTrack(track, stream);
      });
    } else {
      console.log("No valid tracks in the local stream.");
    }

    pc.onicecandidate = (event) => {
      if (event.candidate) {
        socketManager.sendIceCandidate(
          userId,
          event.candidate,
          mainRoom,
          "artist"
        );
      }
    };

    pc.offerSended = false;

    pc.onicecandidateerror = async (event) => {
      const data = {
        callingFunction: "onicecandidateerror",
        msg: "********************",
        errorCode: event.errorCode,
        errorText: event.errorText,
        url: event.url,
        timeStamp: new Date().toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        }),
      };
      socketManager.logEmmitter(data);
      console.log("onicecandidateerro-------------------", event);
    };

    pc.onicegatheringstatechange = async (event) => {
      let connection = event.target;

      const data = {
        callingFunction: "onicegatheringstatechange",
        msg: "###################",
        event: connection,
        connectState: connection.iceGatheringState ?? "No State Avialble",
        timeStamp: new Date().toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        }),
      };
      socketManager.logEmmitter(data);

      switch (connection.iceGatheringState) {
        case "gathering":
          console.log(connection, "gathering");
          break;
        case "complete":
          console.log(connection, "complete");
          // if (pc.offerSended === false) {
          //   pc.offerSended = true;
          //   setTimeout(() => {

          //   }, 1000);
          // }
          break;
        default:
          console.log(connection);
      }
    };

    pc.onnegotiationneeded = (event) => {
      const data = {
        callingFunction: "onnegotiationneeded",
        msg: "$$$$$$$$$$$$$$$$$$$",
        event: event.target,
        timeStamp: new Date().toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        }),
      };
      socketManager.logEmmitter(data);
    };

    pc.oniceconnectionstatechange = async (event) => {
      const data = {
        callingFunction: "oniceconnectionstatechange",
        msg: "&&&&&&&&&&&&&&&&&&",
        event: event.target,
        connectionState: pc.iceConnectionState ?? "No data Avialble",
        retryCount: retryCount,
        timeStamp: new Date().toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        }),
      };
      socketManager.logEmmitter(data);

      if (pc.iceConnectionState === "connected") {
        // TODO set loader false
        const retryData = {
          msg: "Successfully Connected...",
          isLoader: false,
          socketId: userId,
        };
        socketManager.retryLoader(retryData);
      }
      if (pc.iceConnectionState === "failed") {
        console.log(
          `ICE connection failed. Retrying attempt ${retryCount + 1}...`
        );

        // Cleanup the failed peer connection
        pc.close();
        delete peerConnections.current[userId];

        if (retryCount < MAX_RETRIES) {
          retryCount++;
          // Retry: Create a new peer connection and attempt to reconnect
          await retryPeerConnection(
            stream,
            userId,
            mainRoom,
            peerConnections,
            retryCount
          );
        } else {
          console.error("Maximum retry attempts reached. Connection failed.");
          socketManager.retryEmitter(
            "Unable to establish a connection after multiple attempts. Please try again later.",
            userId
          );
        }
      }
      if (pc.iceConnectionState === "disconnected") {
        console.log(
          `ICE connection failed. Retrying attempt ${retryCount + 1}...`
        );

        // Cleanup the failed peer connection
        pc.close();
        delete peerConnections.current[userId];

        if (retryCount < MAX_RETRIES) {
          retryCount++;
          // Retry: Create a new peer connection and attempt to reconnect
          await retryPeerConnection(
            stream,
            userId,
            mainRoom,
            peerConnections,
            retryCount
          );
        } else {
          console.error("Maximum retry attempts reached. Connection failed.");
          socketManager.retryEmitter(
            "Unable to establish a connection after multiple attempts. Please try again later.",
            userId
          );
        }
      }
    };

    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);
    socketManager.sendOffer(userId, offer, mainRoom);
    peerConnections.current[userId] = pc;
  };

  const retryPeerConnection = async (
    stream,
    userId,
    mainRoom,
    peerConnections,
    retryCount
  ) => {
    try {
      console.log(`Retrying peer connection (attempt ${retryCount})...`);
      await createPeerConnectionCreator(
        stream,
        userId,
        mainRoom,
        peerConnections,
        retryCount
      );
      console.log("Peer connection retried successfully.");
    } catch (error) {
      console.error(
        `Retrying peer connection failed on attempt ${retryCount}:`,
        error
      );
    }
  };

  const toggleAudio = (isAudio, stream) => {
    if (stream && stream.getAudioTracks) {
      const audioTracks = stream.getAudioTracks()[0];
      if (audioTracks) {
        // audioTracks.forEach((track) => {
        //   track.enabled = isAudio;
        // });
        audioTracks.enabled = !audioTracks.enabled;
        console.log(
          `Audio ${
            isAudio ? "enabled" : "disabled"
          } for ${audioTracks} track(s).`
        );
      } else {
        console.warn("No audio tracks found in the localStream.");
      }
    } else {
      console.error("Invalid localStream or no audio tracks available.");
    }
  };

  const handleSignalingData = async (data) => {
    const { type, userId, answer, candidate } = data;

    switch (type) {
      case "answer":
        if (data.peerConnections.current[userId]) {
          const pc = data.peerConnections.current[userId];
          if (pc) {
            await pc.setRemoteDescription(new RTCSessionDescription(answer));
          }
        }
        break;

      case "icecandidate":
        const iceCandidate = new RTCIceCandidate(candidate);
        if (data.peerConnections.current[userId]) {
          const pc = data.peerConnections.current[userId];
          await pc.addIceCandidate(iceCandidate);
        }
        break;

      default:
        break;
    }
  };

  const closeAllPeerConnections = (peerConnections) => {
    Object.keys(peerConnections.current).forEach((userId) => {
      const pc = peerConnections.current[userId];
      if (pc) {
        pc.getSenders().forEach((sender) => {
          if (sender.track) {
            sender.track.stop();
          }
        });
        pc.ontrack = null;
        pc.onicecandidate = null;
        pc.oniceconnectionstatechange = null;
        pc.onremovetrack = null;
        pc.onsignalingstatechange = null;
        pc.close();
        // Optionally remove the peer connection from the list
        delete peerConnections.current[userId];
        console.log(`Peer connection with user ${userId} closed.`);
      }
    });
    peerConnections.current = {};
  };

  return {
    createPeerConnectionCreator,
    toggleAudio,
    handleSignalingData,
    closeAllPeerConnections,
  };
};

export default useWebRTC;
