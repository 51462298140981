import React, { useEffect, useRef, useState } from 'react';

const MediaStreamDemo = () => {
  const [localStream, setLocalStream] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    // Function to get local media stream
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setLocalStream(stream);

        // Attach the stream to the video element
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error('Error accessing media devices.', error);
      }
    };

    getMediaStream();

    // // Cleanup function to stop tracks on component unmount
    // return () => {
    //   if (localStream) {
    //     localStream.getTracks().forEach(track => track.stop());
    //   }
    // };
  }, []);

  console.log("stream ", localStream);
  
  return (
    <div>
      <h2>Media Stream Demo</h2>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default MediaStreamDemo;
