import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './VideoPlayer.css';  // Import the CSS file
import { backendUrl } from "../config/keys";

const VideoPlayer = () => {
    const { userId: urlUserId } = useParams();
    const [userId, setUserId] = useState(urlUserId || '');
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    console.log("userId /// ", userId);
    
    const fetchVideos = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.post('/api/past-live-by-user', { userId });
            if (response.data && response.data.videos) {
                setVideos(response.data.videos);
                console.log("response ", response.data.videos);
            }
        } catch (err) {
            setError('Error fetching videos');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userId) {
            fetchVideos();
        }
    }, [userId]);

    useEffect(() => {
        if (urlUserId) {
            setUserId(urlUserId);
        }
    }, [urlUserId]);

    return (
        <div className="container">
            <h1 className="heading">Past Live</h1>
            
            {loading && <p>Loading videos...</p>}
            {error && <p className="error">{error}</p>}
            
            <div className="videoContainer">
                {videos.length > 0 ? (
                    videos.map((video) => (
                        <div key={video._id} className="videoWrapper">
                            <video width="600" controls className="video">
                                <source src={`${backendUrl}${video.path}`} type="video/mp4" />
                            </video>
                        </div>
                    ))
                ) : (
                    !loading && <p>No videos available</p>
                )}
            </div>
        </div>
    );
};

export default VideoPlayer;
