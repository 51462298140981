// // Production
// export const backendUrl = "https://prod-socket.jamrack.net";

// // for jam rack server url
// export const thirdPartServerUrl = 'https://prod-api.jamrack.net';

// // for socket connection
// export const socketConnectUrl = 'https://prod-socket.jamrack.net';

// Development
export const backendUrl = "https://dev-socket.jamrack.net";

// for jam rack server url
export const thirdPartServerUrl = "https://dev-api.jamrack.net";

// for socket connection
export const socketConnectUrl = "https://dev-socket.jamrack.net";
