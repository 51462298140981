import React from "react";
import "./MediaRecord.css";
import { useLocation } from "react-router-dom";

const NotSupported = () => {
  const location = useLocation();
  return (
    <div className="end-stream-page">
      <h1> {location?.state?.data} </h1>
    </div>
  );
};

export default NotSupported;
