import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MediaRecord from "./components/MediaRecord";
import VideoPlayer from "./components//VideoPlayer";
import axios from "axios";
import { backendUrl } from "./config/keys";
import MediaStreamDemo from "./components/MediaStreamDemo";
import EndStreamPage from "./components/EndStreamPage";
import NotSupported from "./components/NotSupported";

// Configure Axios
axios.interceptors.request.use((config) => {
  config.url = backendUrl + config.url;
  config.headers = {
    ...config.headers,
  };
  return config;
});

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/demo" element={<MediaStreamDemo />} />
          <Route
            path="/:artistId/:contentId/:mainroomname/:isAudio/:isFlip"
            element={<MediaRecord />}
          />
          <Route path="/pastlive/:userId" element={<VideoPlayer />} />
          <Route path="/end-stream" element={<EndStreamPage />} />
          <Route path="/not-supported" element={<NotSupported />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
