export const asyncStorage = {
  setItem: (key, value) =>
    new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
        resolve(true);
      } catch (error) {
        reject(error);
      }
    }),

  getItem: (key) =>
    new Promise((resolve, reject) => {
      try {
        const value = localStorage.getItem(key);
        resolve(value ? JSON.parse(value) : null);
      } catch (error) {
        reject(error);
      }
    }),

  removeItem: (key) =>
    new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(key);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    }),
};
